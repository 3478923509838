html,
body,
#root {
  height: 100%;
}

body {
  min-width: 1400px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-thumb {
  background-color: #06d6a0;
}

div.min-scrollbar::-webkit-scrollbar {
  width: 1px!important;
  height: 1px!important;
}

div.min-scrollbar::-webkit-scrollbar-thumb {
  background-color: #eee!important;
}

*::-webkit-scrollbar-button {
  display: none;
}

* {
  -ms-overflow-style: none;
}
* {
  overflow: -moz-scrollbars-none;
}

@keyframes fade-in-enter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in-enter {
  animation: fade-in-enter 225ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

@keyframes fade-out-exit {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-out-exit {
  animation: fade-out-exit 195ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.ul-quick-setup-production {
  color: #818181;
  margin: 0px;
  padding-left: 25px;
  padding-top: 6px;
}

.ul-quick-setup-production li:first {
  margin-bottom: 30px;
}

.ul-quick-setup-production li {
  margin-bottom: 20px;
}

.div-quick-setup-production-description {
  padding: 10px;
  display: flex; 
  flex-direction: column;
  gap: 3px 
}

.div-quick-setup-production-description span {
  color: #818181;
}
